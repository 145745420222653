/* You can add global styles to this file, and also import other style files */
@import '../../../themes/murphy-auto.theme.scss';
@import '../../../themes/_colors.scss';

html, body {
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;

	// overflow-y: hidden;

	background: $ma-gray;

	.primary-background {
		background: $ma-red;
	}
}
